.Agrilo-AccountPage {
    width: 100rem;
    height: fit-content;
    min-height: auto;
    border-radius: 10px;
    background: #FCFCFC;
    position: relative;
    padding-bottom: 10rem;
    left: 250px;
    top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.Agrilo-AccountPage-h1 {
    color: #373737;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: absolute;
    left: 3rem;
}

.Agrilo-AccountPage-line1 {
    position: relative;
    border-bottom: solid 5px #F3F3F7;
    width: 100%;
    margin-top: 3rem;
}

.Agrilo-AccountPage-h3 {
    color: #373737;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: relative;
    margin-left: 1rem;
}

.Agrilo-AccountPage-account-box {
    background-color: #F3F3F7;
    border-radius: 10px;
    margin-top: 3rem;
    width: 40rem;
    height: fit-content;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column
}

.Agrilo-AccountPage-edit-account-box{
    background-color: #F3F3F7;
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.Agrilo-AccountPage-edit-account-box:hover{
    background-color: #E0E0E0;
}


.Agrilo-AccountPage-h2 {
    position: relative;
    padding-top: 0.5rem;
    margin-right: 24rem;
}

.Agrilo-AccountPage-edit-password-box{
    background-color: #F3F3F7;
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.Agrilo-AccountPage-edit-password-box:hover{
    background-color: #E0E0E0;
}

.Agrilo-AccountPage-h2-passowrd {
    position: relative;
    padding-top: 0.5rem;
    margin-right: 21rem;
}

.Agrilo-AccountPage-help-box {
    background-color: #F3F3F7;
    border-radius: 10px;
    margin-top: 3rem;
    width: 40rem;
    height: fit-content;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column
}

.Agrilo-AccountPage-agrilo-help-box {
    background-color: #F3F3F7;
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.Agrilo-AccountPage-agrilo-help-box:hover{
    background-color: #E0E0E0;
}

.Agrilo-AccountPage-h2-agrilo-help {
    position: relative;
    padding-top: 0.5rem;
    margin-right: 23rem;
}

