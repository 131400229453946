.AgriloTestPreviewBox {
    position: relative;
    width: 30rem;
    height: 30rem;
    flex-shrink: 0;
    border-radius: 10px;
    border: 2px solid #F3F3F7;
    background: #FFF;
    min-height: fit-content;
    padding-bottom: 1rem;
}

.AgriloTestPreviewBox-icon {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    left: 20px;
    top: 15px;

}

.AgriloTestPreviewBox-h1 {
    color: #2A2A2A;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: relative;
    top: -1.5rem;
    left: 3rem;
}

.AgriloTestPreviewBox-line1 {
    position: relative;
    border-bottom: solid 2px #F3F3F7;
    top: -1rem;
}

.AgriloTestPreviewBox-h2 {
    color: #5A5A5A;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: relative;
    top: -9px;
    left: 25px;
}

.AgriloTestPreviewBox-h3 {
    color: #B4B4B4;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: relative;
    top: -18px;
    left: 25px;
}

.AgriloTestPreviewBox-placeholder {
    height: 20rem;
    border-top: solid 2px #F3F3F7;
    border-bottom: solid 2px #F3F3F7;
    position: relative;
    top: -20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .AgriloPlaceholder2 {
    width: 164px;
    height: 179px;
} */

.AgriloTestPreviewBox-h4 {
    color: #5A5A5A;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: relative;
    top: -18px;
    left: 25px;
}

.AgriloTestPreviewBox-h5 {
    width: 164px;
    color: #9b9a9a;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.44px;
    position: relative;
    top: -30px;
    left: 25px;
    width: 90%;
}

.AgriloTestPreviewBox-chemical {
    position: absolute;
    color: #000;
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.483px;
    top: -0.5rem;
}


.AgriloTestPreviewBox-metric {
    position: absolute;
    color: #89888D;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.476px;
    top: 10.5rem;
}

.AgriloTestPreviewBox-range-low {
    position: absolute;
    color: #89888D;
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.476px;
    top: 14rem;
    left: 11rem;
}

.AgriloTestPreviewBox-range-high {
    position: absolute;
    color: #89888D;
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.476px;
    top: 14rem;
    right: 11rem;
}

.AgriloTestPreviewBox-rating {
    position: absolute;
    width: fit-content;
    height: 2rem;
    padding: 0rem 0.5rem 0rem 0.5rem;
    flex-shrink: 0;
    border-radius: 1rem;
    background: #4C6CFD;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 17rem;
}

.AgriloTestPreviewBox-rating-text {
    position: relative;
    color: #FFF;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.291px;
}

.AgriloTestPreviewBox-range {
    position: absolute;
    color: #89888D;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.241px;
    top: 12.5rem;
}