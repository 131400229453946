.Agrilo-ChangePassword {
    width: 100rem;
    height: fit-content;
    min-height: auto;
    border-radius: 10px;
    background: #FCFCFC;
    position: relative;
    padding-bottom: 10rem;
    left: 250px;
    top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Agrilo-ChangePassword-back-button {
    position: absolute;
    top: 1rem;
    left: 1rem;
    border: solid 3px #d6d6d8;
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    cursor: pointer;
}

.Agrilo-ChangePassword-back-button:hover {
    background-color: #d6d6d8;
}

.Agrilo-ChangePassword-profile-box {
    border-radius: 10px;
    margin-top: 3rem;
    width: 40rem;
    height: fit-content;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
}

.Agrilo-ChangePassword-input {
    border-radius: 0.25rem;
    width: 100%;
    height: 2.5rem;
    font-size: 1.25rem;
}

.Agrilo-ChangePassword-button-box {
    display: flex;
    width: 30rem;
    margin-top: 2rem;
    margin-left: 20rem;
}

.Agrilo-ChangePassword-cancel-button {
    border: none;
    background-color: #d6d6d8;
    color: #373737;
    font-size: 1.25rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
}

.Agrilo-ChangePassword-cancel-button:hover {
    background-color: #373737;
    color: #d6d6d8;
}

.Agrilo-ChangePassword-save-button {
    border: none;
    background-color: #05ca0f;
    color: black;
    font-size: 1.25rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
    margin-left: 2rem;
}

.Agrilo-ChangePassword-save-button:hover {
    background-color: black;
    color: #FCFCFC;
}