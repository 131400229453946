.AgriloNavagatorBar {
    width: 146px;
    height: 320px;
    top: 125px;
    left: 28px;
    position: fixed;
}

.AgriloNavagatorBar-li {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1rem;
}

.AgriloNavagatorBar-summary-btn {
    border: none;
    position: absolute;
    color: #5A5A5A;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    background-color: #ffffff00;
    cursor: pointer;
}

.AgriloNavagatorBar-summary-menu {
    color: #2A2A2A;
    font-family: Poppins;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: absolute;
    top: 1rem;
    left: -1.5rem;
    list-style: none;
}


.CalendarIcon {
    position: relative;
    right: 3px;
    width: 15px;
    height: 15px;
}

.TasksIcon {
    position: relative;
    right: 4px;
    width: 16px;
    height: 16px;
}

.PinnedIcon {
    position: relative;
    right: 1px;
    margin-right: 6px;
    width: 1rem;
    height: 1.5rem;
}


.AgriloNavagatorBar-history-btn {
    border: none;
    position: absolute;
    color: #5A5A5A;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    background-color: #ffffff00;
    cursor: pointer;
    top: 5rem;
}

.AgriloNavagatorBar-history-menu {
    color: #2A2A2A;
    font-family: Poppins;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: absolute;
    top: 6rem;
    left: -1.5rem;
    list-style: none;
}

.AgriloNavagatorBar-OverviewIcon {
    position: relative;
    margin-right: 6px;
    width: 14px;
    height: 13.5px;
}

.AgriloNavagatorBar-TestsIcon {
    position: relative;
    margin-right: 6px;
    width: 14.3px;
    height: 14px;
}

.AgriloNavagatorBar-RecommendationsIcon {
    position: relative;
    margin-right: 6px;
    width: 15.9px;
    height: 15.6px;
}



.AgriloNavagatorBar-h1 {
    cursor: pointer;
    color: #5A5A5A;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: absolute;
    top: 14rem;
    left: 10px;
}

.AgriloNavagatorBar-h1:hover {
    /* underline */
    text-decoration: underline;
}

.AgriloNavagatorBar-account-btn {
    border: none;
    position: absolute;
    color: #5A5A5A;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    background-color: #ffffff00;
    cursor: pointer;
    display: flex;
    align-items: center;
    top: 12rem;
}

