.Agrilo-HistoryPage {
    width: 100rem;
    height: fit-content;
    min-height: auto;
    border-radius: 10px;
    background: #FCFCFC;
    position: relative;
    padding-bottom: 10rem;
    left: 250px;
    top: 25px;

}

.Agrilo-HistoryPage-h1 {
    color: #373737;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: relative;
    left: 72px;
    top: 14px;
}

.Agrilo-HistoryPage-line1 {
    position: relative;
    border-bottom: solid 2px #F3F3F7;
    top: 14px;
}

.AgriloOverviewBox {
    top: 65px;
}

.AgriloTestsBox {
    top: 0rem;
}

.AgriloTestPreviewBox {
    top: 0rem;
    margin-left: 2rem;
}

.AgriloRecommendationsBox {
    top: -330px;
    left: 69px;
}

.Agrilo-HistoryPage-test-box {
    margin-top: 3rem;
    margin-left: 3rem;
    width: fit-content;
    height: fit-content;
    display: flex;
}

.Agrilo-HistoryPage-test-group-box{
    margin-top: 3rem;
    margin-left: 3rem;
    width: fit-content;
    height: fit-content;
    display: flex;
}