.ZenroProductPage {
    height: auto;
    min-height: 100vh;
    background: linear-gradient(180deg, #4E5E80 0%, #10131A 46.36%);
    color: white;
    font-family: Arial, sans-serif;
}

/* Main Container */
.ZenroProductPage-Container {
    font-family: 'Arial', sans-serif;
    padding-top: 10rem;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ZenroPP-titleDiv{
    margin-bottom: 10rem;
    align-items: center;
    align-self: center;
    align-content: center;
    display: inline;
}

.ZenroPP-Title {
    color: #97ABD8;
    font-family: Poppins;
    font-size: 80px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    text-align: center;
}

.ZenroPP-TitleText {
    color: white;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}

.ZenroPP-Main-Content{
    display: flex;
    justify-content: center;
    gap: 1.25rem;
    padding: 3rem;
    background: transparent;
    backdrop-filter: blur(1.537209391593933px);
    width: 100vw - 3rem;
}

.ZenroPP-Video-Content{
    display: flex;
    justify-content: center;
    gap: 10rem;
    background: radial-gradient(ellipse at top, #5c5c5c, transparent), radial-gradient(ellipse at bottom, #d3d3d3, transparent);
    backdrop-filter: blur(1.537209391593933px);
    width: 100%;
    padding-bottom: 5rem;
    padding-top: 7rem;
}

.ZenroPP-InfoBox{
    background-color: white;
    padding: 3rem;
    width: 15%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
    border-radius: 0.75rem;
    border-color: gray;
    border: 0.715px solid rgba(255, 255, 255, 0.40);
    background: rgba(13, 33, 68, 0.9);
    backdrop-filter: blur(1.537209391593933px);
    
}

.ZenroPP-InfoBox p, h3{
    color: white;
}

.ZenroPP-ImgBlock, .ZenroPP-50-50-first {
    margin: 0;
    padding: 0;
}

    .ZenroPP-ImgBlock img {
        display: block;
        margin: 0;
    }

.ZenroPP-50-50-first {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 0%;
    width: 100%;
    height: 500px;
}

.ZenroPP-ImgBlock {
    position: relative;
    display: inline-block;
    width: 200px;
    height: 200px;
    align-self: flex-start;
    flex-shrink: 2;
}

.ZenroPP-ImgBlock-Img1 {
    width: inherit;
    border-radius: 10px;
    position: inherit;
    z-index: 2;
    display: block;
}

.ZenroPP-ImgBlock-Img2 {
    width: 100%;
    border-radius: 10px;
    position: absolute;
    top: 30px;
    left: 140px;
    z-index: 1;
}

.ZenroPP-ImgBlock-Img3 {
    width: 100%;
    border-radius: 10px;
    position: absolute;
    top: 30px;
    left: -140px;
    z-index: 1;
}

.ZenroPP-TextBlock {
    display: flex; /* Aligns text beside the image block */
    flex-direction: column;
    align-items: center;
    width: 40%;
    max-width: 30%; /* Optional: Limit the width of the text block */
    align-self: center;
    color: #97ABD8;
}
.ZenroPP-Download-Container{
    display: inline;
    align-items: center;
    align-self: center;
    text-align: center;
}

.ZenroPP-TextBlock-Title {
    color: #7EA6FF;
    padding-bottom: 40px;
    font-size: 3rem;
    text-align: center;
}

h2, p {
    margin: 0 0 10px; /* Add some spacing between the header and paragraph */
    line-height: 1.4; /* Reset margins for better alignment */
}

.ZenroPP-Download-Button-Style{
    background-color: transparent;
    padding: 1rem;
    width: 6rem;
    border-radius: 2rem;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.699);
    color: white;
    font-size: 1rem;
    margin: 1rem;
    cursor: pointer;
}

.ZenroPP-UL {
    text-align: left; /* Ensures the text is left-aligned */
    list-style-position: inside;
}

.ZenroPP-LI {
    margin-bottom: 20px;
}

.ZenroPP-VideoBlock video{
    width: 70%;
    height: auto;
}

.AgriloPP-VideoBlock video{
    width: 60%;
    height: auto;
}

@media (max-width: 900px){
    .ZenroPP-ImgBlock{
        scale: .7;
    }
    .ZenroPP-UL{
        display: none;
    }
    .agriloTopAppsImage{
        display: none;
    }
    .ZenroPP-Main-Content:nth-child(4) {
        align-items: center;
        padding: 0;
        padding-top: 2rem;
    }
    
    .ZenroPP-Main-Content, .ZenroPP-Video-Content{
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    .ZenroPP-TextBlock{
        width: 70%;
        max-width: 70%;
        text-align: center;
        align-items: center;
        align-content: center;
    }
    .ZenroPP-InfoBox{
        width: 70%;
    }
    .ZenroPP-TextBlock p{
        font-size: 1rem;
        padding: 0;
        margin: 0;
    }
}

@media (max-width: 650px){
    .ZenroPP-Title{
        font-size: 3rem;
    }
    .ZenroPP-TitleText{
        font-size: 1.5rem;
    }
    .ZenroPP-ImgBlock{
        scale: .5;
    }
    .ZenroPP-TitleText{
        padding: 0% 15%;
    }
}

@media (max-width: 525px){
    .ZenroPP-Title{
        font-size: 3rem;
    }
    .ZenroPP-TitleText{
        font-size: 1.25rem;
    }
    .ZenroPP-ImgBlock{
        scale: .4;
    }
    .ZenroPP-TextBlock{
        text-align: center;
        max-width: 80%;
        width: 90%;
    }
    .ZenroPP-TextBlock-Title{
        font-size: 2rem;
    }
}