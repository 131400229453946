.Agrilo-EditAccountPage {
    width: 100rem;
    height: auto;
    padding-bottom: 100px;
    border-radius: 10px;
    background: #FCFCFC;
    position: relative;
    left: 250px;
    top: 25px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.Agrilo-EditAccountPage-back-btn {
    position: absolute;
    top: 1rem;
    left: 1rem;
    border: solid 3px #d6d6d8;
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    cursor: pointer;
}

.Agrilo-EditAccountPage-back-btn:hover {
    background-color: #d6d6d8;
}

.Agrilo-EditAccountPage-profile-box {
    border-radius: 10px;
    margin-top: 3rem;
    width: 40rem;
    height: fit-content;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
}

.Agrilo-EditAccountPage-input {
    border-radius: 0.25rem;
    width: 100%;
    height: 3rem;
    font-size: 1.25rem;
}

.Agrilo-EditAccountPage-button-box {
    display: flex;
    margin-top: 2rem;
    margin-left: 28rem;
}

.Agrilo-EditAccountPage-cancel-button {
    border: none;
    background-color: #d6d6d8;
    color: #373737;
    font-size: 1.25rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
}

.Agrilo-EditAccountPage-cancel-button:hover {
    background-color: #b3b3b3;
}

.Agrilo-EditAccountPage-save-button {
    border: none;
    background-color: #05ca0f;
    color: #FCFCFC;
    font-size: 1.25rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
    margin-left: 1rem;
}

.Agrilo-EditAccountPage-save-button:hover {
    background-color: #1a1a1a;
}

.Agrilo-EditAccountPage-h3 {
    color: #373737;
    margin-top: 0rem;
}