.ContactBox {
    width: 513px;
    height: 204px;
    flex-shrink: 0;
    border-radius: 11.529px;
    border: 0.715px solid rgba(255, 255, 255, 0.40);
    background: rgba(250, 250, 250, 0.08);
    backdrop-filter: blur(1.537209391593933px);
}

.support-header {
    width: 91px;
    height: 27px;
    flex-shrink: 0;
    color: #FFF;
    font-family: Poppins;
    font-size: 18.9px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.756px;
    position: absolute;
    left: 34px;
    top: 5px;
}

.support-text {
    width: 500px;
    color: #6D7176;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 19.2px; /* 137.143% */
    letter-spacing: 0.28px;
    position: absolute;
    line-height: 1.5;
    top: 50px;
    left: 34px;
}

.support-box a:hover {
    text-decoration: underline;
}

.support-box a {
    position: absolute;
    top: 120px;
    left: 34px;
    color: #3b9aff;
    text-decoration: none;
    font-weight: bold;
}